import { BusinessUnit } from '@ncs-frontend-monorepo/utils';

export * from './FAQCategories';
export * from './FAQCategoryPage';
export * from './FAQPage';

export type FAQCategory = {
  title: string;
  seoTitle: string;
  seoDescription: string;
  seoHeadline: string;
  seoText: string;
  slug: string;
  icon: number;
  uid: number;
  businessUnit: BusinessUnit;
  '@id': string;
};
