import { Spacer, Text } from 'dss-ui-library';
import { FAQResults } from '..';
import { ChevronLink } from '@ncs-frontend-monorepo/content-library';
import styles from './FAQList.module.scss';
import { FAQ_URL_PATH } from '../../constants';
import { FetchFAQListParams } from '../..';

type FAQListProps = {
  faqs: FAQResults;
  title: string;
  params?: FetchFAQListParams;
};

export const FAQList: React.FC<FAQListProps> = ({ faqs, title, params }) => {
  if (faqs.items.length === 0) {
    return (
      <Spacer b={4} block>
        <Text appearance="t2" element="h2">
          Keine Ergebnisse zu "{title}".
        </Text>
      </Spacer>
    );
  }
  return (
    <>
      <Text appearance="t2" element="h2">
        {faqs.totalItems} {`${faqs.totalItems > 1 ? 'Ergebnisse' : 'Ergebnis'}`}{' '}
        zu "{title}":
      </Text>
      <Spacer b={4} block />
      {faqs.items.map((faq) => (
        <article
          key={faq.uid}
          className={styles.faqListItem}
          data-e2e="faq-list-item"
        >
          <ChevronLink
            url={FAQ_URL_PATH + faq.slugBreadcrumb + `?page=${params?.page}`}
            className={styles.faqListItemLink}
          >
            <Text appearance="t4_2" className={styles.faqListItemQuestion}>
              {faq.question}
            </Text>
            <Text
              appearance="t4"
              className={styles.faqListItemTeaser}
              element="p"
              color="black"
            >
              {faq.teaser}
            </Text>
          </ChevronLink>
        </article>
      ))}
    </>
  );
};
