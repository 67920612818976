import { FAQ } from '../faqs/index';
import { FAQ_URL_PATH } from '../constants';
import { BreadcrumbItem } from '@ncs-frontend-monorepo/content-library';
import { getEnv } from '@ncs-frontend-monorepo/utils';

export const getDefaultFAQBreadcrumb = (): BreadcrumbItem[] => [
  {
    title: getEnv().BUSINESS_UNIT === 'PK' ? 'Privatkunden' : 'Geschäftskunden',
    urlPath: process.env.NEXT_PUBLIC_SITE_PREFIX || '',
    pageId: 0,
  },
  {
    title: 'FAQ',
    urlPath: FAQ_URL_PATH,
    pageId: 0,
  },
];
export const getFAQBreadcrumb = (faq: FAQ): BreadcrumbItem[] => {
  let breadcrumbs: BreadcrumbItem[] = getDefaultFAQBreadcrumb();

  if (faq.parentCategory) {
    breadcrumbs = [
      ...breadcrumbs,
      ...[
        {
          title: faq.parentCategory.title,
          urlPath: `${FAQ_URL_PATH}/${faq.parentCategory.slug}`,
          pageId: faq.parentCategory.uid,
        },
      ],
    ];
  }

  if (faq.parentCategory && faq.mainCategory) {
    breadcrumbs = [
      ...breadcrumbs,
      ...[
        {
          title: faq.mainCategory.title,
          urlPath: `${FAQ_URL_PATH}/${faq.parentCategory?.slug}/${faq.mainCategory.slug}/`,
          pageId: faq.mainCategory.uid,
        },
      ],
    ];
  }

  breadcrumbs.push({
    title: faq.question,
    urlPath: null,
    pageId: faq.uid,
  });

  return breadcrumbs;
};
