import { BusinessUnit } from '@ncs-frontend-monorepo/utils';
import { ContentVideo } from '@ncs-frontend-monorepo/content-library';

export * from './List';

export type Categories = {
  title: string;
  slug: string;
  uid: number;
  '@id': string;
};

export type FAQ = {
  title: string;
  seoTitle: string;
  seoDescription: string;
  seoHeadline: string;
  seoText: string;
  question: string;
  teaser: string;
  answer: string;
  slug: string;
  slugBreadcrumb: string;
  helpful: number;
  notHelpful: number;
  parentCategory?: Categories | null;
  mainCategory?: Categories | null;
  businessUnit: BusinessUnit;
  contentElements?: ContentVideo[];
  uid: number;
  '@id': string;
};

export type FAQResults = {
  items: FAQ[];
  totalItems: number;
  page: number;
};
