import {
  BUSINESS_UNIT,
  fetcher,
  getEnv,
  replaceDynamicText,
} from '@ncs-frontend-monorepo/utils';
import { FAQ, FAQCategory, FAQResults } from '..';
import { NUMBER_OF_FAQS } from '../constants';
import { getReplaceText } from '../helper';

type FetchFAQCategoriesParams = {
  parentCategoryUID: number;
};

export type FetchFAQListParams = {
  categoryUID: number;
  page?: number;
  itemsPerPage?: number;
};

export type FetchFAQParams = {
  faqUID: number;
};

export async function fetchFAQCategories({
  parentCategoryUID,
}: FetchFAQCategoriesParams): Promise<FAQCategory[]> {
  const params = new URLSearchParams({
    parentCategory_uid: parentCategoryUID.toString(),
  });

  const response = await fetcher(`${getEnv().API_V2_URL}/categories?${params}`);

  return response?.['hydra:member'] || [];
}

export async function fetchFAQList({
  categoryUID,
  page = 1,
  itemsPerPage = NUMBER_OF_FAQS,
}: FetchFAQListParams): Promise<FAQResults | null> {
  const params = new URLSearchParams({
    categories_uid_recursive: categoryUID.toString(),
    itemsPerPage: itemsPerPage.toString(),
    page: page.toString(),
  });

  const businessUnit = `businessUnit[]=${
    getEnv().BUSINESS_UNIT
  }&businessUnit[]=${BUSINESS_UNIT.GKandPK}`;

  const response = await fetcher(
    `${getEnv().API_V2_URL}/faqs?${businessUnit}&${params}`,
  );

  if (!response) {
    return null;
  }

  const replaceText = getReplaceText();
  const items = response['hydra:member'].map((faq: FAQ) => ({
    ...faq,
    question: replaceDynamicText(faq.question, replaceText),
    teaser: replaceDynamicText(faq.teaser, replaceText),
  }));

  return {
    items,
    totalItems: response['hydra:totalItems'],
    page: response['hydra:view']['hydra:page'],
  };
}
export async function fetchFAQ({
  faqUID,
}: FetchFAQParams): Promise<FAQ | null> {
  const response = await fetcher(`${getEnv().API_V2_URL}/faqs/${faqUID}`);
  if (!response) {
    return null;
  }

  const replaceText = getReplaceText();

  response.question = replaceDynamicText(response.question, replaceText);
  response.answer = replaceDynamicText(response.answer, replaceText);

  return response;
}
