import { CATEGORY_UID, FAQ_URL_PATH } from '../constants';
import { fetchFAQCategories } from '..';
import { ContentType } from '@ncs-frontend-monorepo/content-library';
import { getFAQPageType } from './getFAQPageType';
import { getFAQCategoryPage } from './getFAQCategoryPage';
import { getFAQPage } from './getFAQPage';

export * from './requests';

export const getFAQPageProps = async (path: string) => {
  const pageType = await getFAQPageType(path);

  if (!pageType) {
    return null;
  }

  if (pageType?.type === ContentType.FAQ_CATEGORY_PAGE) {
    return await getFAQCategoryPage({
      path,
      mainCategoryUID: pageType.mainCategoryUID,
      activeCatgoryUID: pageType.activeCatgoryUID,
      category: pageType.category,
    });
  }

  if (pageType?.type === ContentType.FAQ_PAGE) {
    return await getFAQPage({ path, categoryUID: pageType.categoryUID });
  }

  return null;
};

export const createFAQSitemap = async () => {
  // get all faq categories
  const mainCategories = await fetchFAQCategories({
    parentCategoryUID: CATEGORY_UID.NC,
  });

  if (!mainCategories) {
    return [];
  }

  const faqPaths = await Promise.all(
    mainCategories.map(async (mainCategory) => {
      const subCategory = await fetchFAQCategories({
        parentCategoryUID: mainCategory.uid,
      });

      const subCategoryPaths = subCategory.map(
        (subCategory) =>
          `${FAQ_URL_PATH}/${mainCategory.slug}/${subCategory.slug}/`,
      );

      return [
        `${FAQ_URL_PATH}/${mainCategory.slug}/`,
        ...(subCategoryPaths.length > 0 ? subCategoryPaths : []),
      ];
    }),
  );

  return faqPaths.flat();
};
