import { Grid, Text, Spacer, RichText } from 'dss-ui-library';
import { ChevronLink, Video } from '@ncs-frontend-monorepo/content-library';
import { FAQ } from '../../faqs';
import { FAQ_URL_PATH } from '../../constants';
import styles from './FAQ.module.scss';
import { useFAQStore } from '@ncs-frontend-monorepo/utils';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export interface FAQPageProps {
  faq: FAQ;
  faqList: { items: FAQ[] };
}

export const FAQPage: React.FC<FAQPageProps> = ({ faq }) => {
  const { selectedCategoryUID } = useFAQStore();
  const router = useRouter();
  const backToUrl = useMemo(() => {
    const url = FAQ_URL_PATH + '/' + faq.parentCategory?.slug;
    if (selectedCategoryUID === faq.mainCategory?.uid) {
      return `${url}/${faq.mainCategory.slug}`;
    }
    return url;
  }, [selectedCategoryUID, faq.parentCategory?.slug, faq.parentCategory?.uid]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <ChevronLink
              appearance="t4_2"
              rotation="left"
              url={backToUrl + `?page=${router.query.page || 1}`}
            >
              Zurück zur Übersicht
            </ChevronLink>
          </Grid.Column>
        </Grid.Row>
        <Spacer />
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t1_2" element="h1">
              {faq.question}
            </Text>
            <Spacer y={2} className={styles.richTextTable}>
              <RichText text={faq.answer} appearance="t4" />
            </Spacer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {faq.contentElements?.map((c) => (
        <Video {...c.props} />
      ))}
    </>
  );
};
